<template>
  <view-wrapper :title="`订单号：${form.data.serialNo}`">
    <template #operation>
      <span v-if="form.data.status.code === 'P'">
        <a-button :loading="rejectLoading" @click="form.reject.click"> 拒绝 </a-button>
        <a-button :loading="auditLoading" @click="form.audit.click" style="margin-left: 10px" type="primary"
          >接单
        </a-button>
      </span>
      <a-button v-if="form.data.status.code === 'C' && isShowSendOut" @click="drawer.open.click" type="primary"
        >去发货
      </a-button>
    </template>
    <template #header>
      <a-spin :spinning="form.loading">
        <a-form class="form-info" style="position: relative">
          <img v-if="form.data.status.code === 'R'" class="cancel-icon" :src="CancelImg" alt="" />
          <a-row>
            <a-col :span="7">
              <a-form-item label="采购商：">
                <span>{{ form.data.tenantShortName }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="联系方式：">
                <span>{{ form.data.inquiry.addressCellphone }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="收件人：">
                <span>{{ form.data.inquiry.addressContactName }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="收货地址：">
                <span>{{ address }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </template>
    <div style="padding: 0 24px 24px 24px; background: #fff">
      <a-spin :spinning="form.loading">
        <div class="title-text">配件清单</div>
        <a-table :pagination="false" rowKey="id" :data-source="form.data.purchaseGoods" :columns="columns">
          <template #price="{ text }">
            <span class="font-bold text-[16px]">{{ formatMoney(text, '￥') }}</span>
          </template>
          <template #count="{ text }">
            <span>{{ text }}</span>
          </template>
          <template #subtotal="{ text }">
            <span class="font-bold text-[16px]">{{ formatMoney(text, '￥') }}</span>
          </template>
          <template #warrantyMonth="{ text }">
            <span>{{ text }}个月</span>
          </template>
          <template #reserveDays="{ text }">
            <span>{{ !text ? '现货' : `订货${text}天` }}</span>
          </template>
          <template #status="{ record }">
            <a-badge v-if="record.status.code === 'P'" status="error" text="待发货" />
            <a-badge v-else status="success" text="已发货" />
          </template>
        </a-table>
        <div class="flex items-center justify-end mt-[20px]">
          <span class="text-[16px]">
            <span>金额总计</span>
            <span class="font-bold">{{ formatMoney(totalAmount, '￥') }}</span>
          </span>
        </div>
        <div class="title-text">订单流程</div>
        <div>
          <div class="steps-header">
            <ul class="steps__tr">
              <li class="steps__th">处理时间</li>
              <li class="steps__th">处理信息</li>
              <li class="steps__th">操作人</li>
            </ul>
          </div>
          <div class="steps-content">
            <ul class="steps__tr" v-for="item in form.data.documentLogs" :key="item.id">
              <li class="steps__td">
                <div>
                  <span class="steps-icon-dot"></span>
                  <span>{{ formatDate(item.datetime, true) }}</span>
                </div>
                <div class="steps-icon-line"></div>
              </li>
              <li class="steps__td">{{ item.comment }}</li>
              <li class="steps__td">{{ item.operatorName }}</li>
            </ul>
          </div>
        </div>
        <div class="title-text">发票信息</div>
        <div v-if="form.data.inquiry.invoiceType && form.data.inquiry.invoiceType.code !== 'N'" class="invoice-block">
          <a-row>
            <a-col :span="7">
              <a-form-item label="发票抬头：">
                <span>{{ form.data.inquiry.invoiceTitle }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="纳税人识别号：">
                <span>{{ form.data.inquiry.invoiceTaxNo }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="公司电话：">
                <span>{{ form.data.inquiry.invoiceCompanyPhone }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="公司地址：">
                <span>{{ form.data.inquiry.invoiceCompanyAddress }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="开户行：">
                <span>{{ form.data.inquiry.invoiceBankName }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="银行账号：">
                <span>{{ form.data.inquiry.invoiceBankAccount }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <p v-else>不需要发票</p>
      </a-spin>
    </div>
    <a-drawer title="去发货" :visible="drawer.visible" width="480" @close="drawer.close">
      <a-form layout="vertical">
        <a-table
          :pagination="false"
          :data-source="drawer.selectedGoods"
          rowKey="id"
          :rowSelection="{ onChange: drawer.onSelectChange }"
          style="margin-bottom: 24px"
        >
          <a-table-column title="配件名称" data-index="quotationGoods.inquiryGoods.name" />
          <a-table-column title="数量" data-index="count" />
        </a-table>
        <a-form-item label="配送方式">
          <a-select placeholder="请选择配送方式" @change="drawer.logisticsTypeChange">
            <a-select-option v-for="item in methods" :key="item.code" :value="item.code">{{
              item.message
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="物流公司">
          <e-select
            style="width: 100%"
            :ajax="{ get: { action: 'GET /common/logistics/company' } }"
            placeholder="请选择物流平台"
            allowCreate
            v-model="drawer.form.data.logisticsName"
            :props="{ label: 'name', value: 'name' }"
          ></e-select>
        </a-form-item>
        <a-form-item label="物流单号">
          <a-input v-model:value="drawer.form.data.logisticsNo" placeholder="请输入物流单号" />
        </a-form-item>
      </a-form>
      <div class="drawer-footer">
        <a-button style="margin-right: 8px" @click="drawer.close">取消</a-button>
        <a-button type="primary" @click="drawer.confirm.click" :loading="deliverLoading">确认</a-button>
      </div>
    </a-drawer>
  </view-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, createVNode } from 'vue'
import { useAjax, formatDate, formatMoney } from '@vue-mfe/utils'
import { useRouter, useRoute } from 'vue-router'
import {
  message,
  Modal,
  Spin as ASpin,
  Drawer as ADrawer,
  Badge as ABadge,
  SelectOption as ASelectOption,
  Row as ARow,
  Col as ACol,
  Select as ASelect
} from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import CancelImg from '@supplier/assets/cancel.png'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'

export default defineComponent(
  {
    name: '',
    // props: {
    //   id: {
    //     type: String,
    //     required: true
    //   }
    // },
    components: {
      ARow,
      ACol,
      ASpin,
      ADrawer,
      ABadge,
      ASelect,
      ASelectOption,
      ExclamationCircleOutlined
    },
    setup () {
      const router = useRouter()
      const route = useRoute()
      const id = route.params.id
      const { data: methods } = useAjax(
        {
          action: 'GET /common/lookup/:lookupType',
          params: 'LGSTYPE'
        }
      )
      const [
        getDetail,
        { run: auditPurchase, loading: auditLoading },
        { run: closePurchase, loading: rejectLoading },
        { run: deliver, loading: deliverLoading }
      ] = [
        useAjax(
          {
            action: 'GET /supplier/purchase/:purchaseId',
            params: id,
            lazy: true
          }
        ),
        useAjax(
          {
            action: 'POST /supplier/purchase/:purchaseId/audit',
            params: id,
            lazy: true,
            success () {
              message.success('接单成功')
              form.get()
            }
          }
        ),
        useAjax(
          {
            action: 'POST /supplier/purchase/:purchaseId/close',
            params: id,
            lazy: true,
            success () {
              message.success('已拒绝')
              router.push({ path: '/supplier/supply/order' })
            }
          }
        ),
        useAjax(
          {
            action: 'POST /supplier/purchase/deliver',
            params: () =>
              Object.assign(
                drawer.form.data,
                {
                  purchaseId: id
                }
              ),
            success () {
              drawer.visible = false
              message.success('发货成功')
              form.get()
            },
            lazy: true
          }
        )
      ]
      const form = reactive(
        {
          loading: getDetail.loading,
          data: {
            serialNo: '',
            branchName: '',
            tenantShortName: '',
            cellphone: '',
            commitBy: '',
            preparedBy: '',
            status: {
              code: ''
            },
            inquiry: {
              addressDetail: '',
              addressContactName: '',
              addressCellphone: '',
              addressArea: {
                code: '',
                name: '',
                parent: {
                  name: '',
                  parent: {
                    name: ''
                  }
                }
              },
              invoiceBankAccount: '',
              invoiceBankName: '',
              invoiceCompanyAddress: '',
              invoiceCompanyPhone: '',
              invoiceTaxNo: '',
              invoiceTitle: '',
              invoiceType: {
                code: ''
              }
            },
            purchaseGoods: [] as any[],
            documentLogs: [] as any[]
          },
          get () {
            getDetail.run().then(
              (res) => {
                form.data = Object.assign(form.data, res.data.data[0])
              }
            )
          },
          audit: {
            click () {
              auditPurchase()
            }
          },
          reject: {
            click () {
              Modal.confirm(
                {
                  icon: () => createVNode(ExclamationCircleOutlined),
                  cancelText: () => '取消',
                  content: () => '确认要拒绝这条采购单吗？',
                  onCancel: () => {},
                  onOk: () => closePurchase()
                }
              )
            }
          }
        }
      )
      form.get()
      const drawer = reactive(
        {
          visible: false,
          selectedGoods: [] as any[],
          form: {
            data: {
              logisticsType: {} as object,
              logisticsName: '' as string,
              logisticsNo: '' as string,
              purchaseGoods: [] as any[]
            },
            init () {
              drawer.form.data.logisticsType = {}
              drawer.form.data.logisticsName = ''
              drawer.form.data.logisticsNo = ''
              drawer.form.data.purchaseGoods = []
            }
          },
          logisticsTypeChange (code: any) {
            const row = methods.value?.find((item: any) => item.code === code)
            if (row) drawer.form.data.logisticsType = row
          },
          onSelectChange (selectedRowKeys: [], selectedRows: []) {
            console.log(selectedRowKeys, 'selectedRowKeys')
            drawer.form.data.purchaseGoods = selectedRows
          },
          open: {
            click () {
              drawer.selectedGoods = form.data.purchaseGoods.filter((item: any) => item.status.code === 'P')
              // if (!drawer.selectedGoods.length) return message.warning('无可发货配件')
              drawer.form.init()
              drawer.visible = true
            }
          },
          close () {
            drawer.selectedGoods = []
            drawer.visible = false
          },
          confirm: {
            click () {
              deliver()
            }
          }
        }
      )
      const address = computed(
        () => {
          const { addressArea, addressDetail } = form.data.inquiry
          if (addressArea)
            return addressArea.parent && addressArea.parent.parent
              ? `${addressArea.parent.parent.name}/${addressArea.parent.name}/${addressArea.name} ${addressDetail}`
              : `${addressArea.parent.name}/${addressArea.name} ${addressDetail}`
          return ''
        }
      )
      const columns = computed(
        () => {
          let arr = [
            { title: '配件名称', with: '100px', ellipsis: true, dataIndex: 'quotationGoods.inquiryGoods.name' },
            { title: '配件编码', dataIndex: 'quotationGoods.oem' },
            { title: '单价', dataIndex: 'price', slots: { customRender: 'price' } },
            { title: '数量', dataIndex: 'count', slots: { customRender: 'count' } },
            { title: '小计', dataIndex: 'amount', slots: { customRender: 'subtotal' } },
            { title: '品质类型', dataIndex: 'quotationGoods.qualityType.message' },
            {
              title: '质保周期',
              dataIndex: 'quotationGoods.warrantyMonth',
              customRender: ({ text }) => `${text || 0}个月`
            },
            {
              title: '订货周期',
              dataIndex: 'quotationGoods.reserveDays',
              customRender: ({ text }) => (!text ? '现货' : `订货${text}天`)
            }
          ] as ColumnProps[]
          if (form.data.status.code === 'C') {
            const column = { title: '状态', slots: { customRender: 'status' } } as ColumnProps
            arr.push(column)
          }
          return arr
        }
      )
      const isShowSendOut = computed(
        () => {
          return form.data.purchaseGoods.filter((item: any) => item.status.code === 'P').length
        }
      )
      const totalAmount = computed(
        () => {
          return form.data.purchaseGoods.reduce((cur, next: any) => cur + next.amount, 0)
        }
      )
      return {
        columns,
        form,
        drawer,
        address,
        methods,
        CancelImg,
        auditLoading,
        rejectLoading,
        deliverLoading,
        formatDate,
        formatMoney,
        isShowSendOut,
        totalAmount
      }
    }
  }
)
</script>

<style scoped>
.title-text {
  font-size: 16px;
  padding-top: 24px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}
.mySteps.ant-steps-dot :deep(.ant-steps-item-content) {
  width: 80%;
}
.mySteps.ant-steps-dot :deep(.ant-steps-item-subtitle) {
  margin-left: 60px;
}
.mySteps.ant-steps-dot .nt-steps-item-active :deep(.ant-steps-item-subtitle) {
  color: rgba(0, 0, 0, 0.85);
}
.mask {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  /* background: rgba(255,255,255,0.3); */
}
.cancel-icon {
  position: absolute;
  right: 100px;
  bottom: 0px;
  display: block;
  width: 145px;
  height: 145px;
}
.steps-header {
  background: #fafafa;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #e8e8e8;
}
.steps-content {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 0;
}
.steps__tr {
  display: flex;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.65);
}

.steps__th {
  padding: 16px 0;
  font-weight: bold;
}
.steps__th:nth-of-type(1),
.steps__td:nth-of-type(1) {
  width: 250px;
}
.steps__th:nth-of-type(2),
.steps__td:nth-of-type(2) {
  flex: 1;
}
.steps__th:nth-of-type(3),
.steps__td:nth-of-type(3) {
  width: 100px;
}
.steps-icon-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
}
.steps-icon-line {
  width: 1px;
  height: 12px;
  background: #c4c4c4;
  margin-left: 3px;
}
.steps-content > .steps__tr:first-child {
  color: rgba(0, 0, 0, 0.85);
  /* font-weight: bold; */
}
.steps-content > .steps__tr:first-child .steps-icon-dot,
.steps-content > .steps__tr:first-child .steps-icon-line {
  background: #0d8d8d;
}
.steps-content > .steps__tr:last-child .steps-icon-line {
  display: none;
}
.invoice-block :deep(.ant-row.ant-form-item) {
  margin-bottom: 0;
}
</style>
